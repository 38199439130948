import React, { useEffect } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import NewAgencyCards from "../components/NewAgencyCards"
import FilterDropdownItem from "../components/FilterDropdownItem"
import useCheckboxFilter from "../hooks/useCheckboxFilter"
import { useState } from "react"
import MobileFilters from "../components/MobileFilters"
import CallToAction from "../components/CallToAction"
import ModalForm from "../components/ModalForm"
import FilterPopup from "../components/Filterpopup"
import { Link } from "gatsby"
import SEO from "../components/seo"
import Faq from "../components/Faq"
const AgencyCountryTemplate = ({ data, location, pageContext }) => {
  const uniqueTitles = new Set()
  const uniqueElements = data.allContentfulAgencyCategory.nodes
    .flatMap(e =>
      e?.ageny_experts?.flatMap(el =>
        el?.cityLocation?.flatMap(c =>
          c?.country?.flatMap(con =>
            el?.agencyCategory?.map(d => ({
              title: c?.title,
              dSlug: d?.slug,
              dTitle: d?.title,
              conSlug: con?.slug,
              cSlug: c?.citySlug,
            }))
          )
        )
      )
    )
    .filter(item => {
      const key = `${item?.title}-${item?.dSlug}`
      if (!uniqueTitles.has(key)) {
        uniqueTitles.add(key)
        return true
      }
      return false
    })
  // .filter(item => {
  //   if (!uniqueTitles.has(item.title)) {
  //     uniqueTitles.add(item.title)
  //     return true
  //   }
  //   return false
  // })

  const otherCityAgencies = uniqueElements.filter(
    e => e.dSlug === location.pathname.split("/")[2]
  )

  const metaData = data.allContentfulAgenyExperts.nodes
    .map(e => e?.cityLocation)
    .flat(1)
    .map(el => el?.country)
    .flat(1)
    .map(s => s?.categoriesWithMetaInfo)
    .flat(1)

  const metaInfo = metaData.find(
    e => e.category.slug === location.pathname.split("/")[2]
  )

  const slugCategoryValue = location.pathname.split("/")[2]

  const otherServices = data.allContentfulAgencyCategory.nodes.filter(
    e => e.slug != slugCategoryValue
  )

  const agencyRate = data.allContentfulAgenyExperts.nodes.map(el => el.price)
  const agencyProjectSize = data.allContentfulAgenyExperts.nodes.map(
    el => el.projectSize
  )
  const cityLocations = data.allContentfulAgenyExperts.nodes
    .map(w => w.cityLocation)
    .flat(1)

  const uniqueCityLocations = []
  const seenHashes = new Set()

  for (const obj of cityLocations) {
    const hash = JSON.stringify(obj)
    if (!seenHashes.has(hash)) {
      seenHashes.add(hash)
      uniqueCityLocations.push(obj)
    }
  }
  const agencyLocation = data.allContentfulAgenyExperts.nodes.map(el => el.city)

  const agencyEmployeeSize = data.allContentfulAgenyExperts.nodes.map(
    el => el.employeeSize
  )
  const [filterData, setFilterData] = useState([
    {
      heading: "Rate/hour",
      title: "Rate/hour",
      filterData: agencyRate,
      open: false,
    },
    {
      heading: "Min. Project Size",
      title: "projectSize",
      filterData: agencyProjectSize,
      open: false,
    },
    {
      heading: "Employees",
      title: "employeeSize",
      filterData: agencyEmployeeSize,
      open: false,
    },
    {
      heading: "Location",
      title: "city",
      filterData: agencyLocation,
      open: false,
    },
  ])
  const [showModal, setShowModal] = useState(false)

  const handleDropdown = index => {
    const updatedFilterData = filterData.map((el, i) => {
      return { ...el, open: i === index ? !el.open : false }
    })
    setFilterData(updatedFilterData)
  }
  const [products, setProducts] = useState(data.allContentfulAgenyExperts.nodes)
  const [showFilter, setShowFilter] = useState(false)
  const handleModal = () => {
    setShowFilter(!showFilter)
  }
  const [filters, setFilters] = useState({
    "Rate/hour": [],
    city: [],
    employeeSize: [],
    projectSize: [],
  })

  const {
    // filters,
    // setFilters,
    filteredProducts,
    handleFilterChange,
    indexOfLastItem,
    indexOfFirstItem,
    currentItems,
    currentPage,
    itemsPerPage,
    clearElement,
    paginate,
    renderPageButtons,
    totalPages,
    allFilterValues,
    setAllFilterValues,
    filterValues,
    sortBy,
    handleSortChange,
    clearAllFilters,
    applyFilter,
  } = useCheckboxFilter(
    filterData,
    products,
    data.allContentfulAgenyExperts.nodes,
    location,
    filters,
    setFilters
  )

  const RemoveFilterValues = e => {
    // Determine the filter category based on the filter value (example logic)
    let filterCategory = ""

    if (filters["Rate/hour"].includes(e)) {
      filterCategory = "Rate/hour"
    } else if (filters.city.includes(e)) {
      filterCategory = "city"
    } else if (filters.employeeSize.includes(e)) {
      filterCategory = "employeeSize"
    } else if (filters.projectSize.includes(e)) {
      filterCategory = "projectSize"
    }

    if (filterCategory) {
      // Remove the filter value from allFilterValues
      const updatedFilterValues = allFilterValues.filter(el => el !== e)
      setAllFilterValues(updatedFilterValues)

      // Remove the filter value from the corresponding filter category in filters state
      setFilters(prevFilters => ({
        ...prevFilters,
        [filterCategory]: prevFilters[filterCategory].filter(val => val !== e),
      }))
    }
  }

  return (
    <>
      <Layout data={{ headerType: "dark" }}>
        <SEO
          title={metaInfo?.seoTitle}
          description={metaInfo?.seoDescription?.seoDescription}
          url={location.href}
        />
        <section className="new-agency-hero">
          <div className="container-lg">
            <div className="new-agency-hero__breadcrumbs text-fw-regular text-white1">
              <a href="/agencies" className="text-white1">
                Home
              </a>
              /
              <a
                href={`/agencies/${pageContext?.filter1}`}
                className="text-white1"
              >
                {pageContext?.categoryTitle}
              </a>
              /
              <a
                href={`/agencies/${pageContext?.filter2}`}
                className="text-white1"
              >
                {pageContext?.countryTitle}
              </a>
            </div>
            <div className="new-agency-hero__text ">
              <a href="#" className="text-black1 text-fw-bold">
                2023 Reviews
              </a>
              <h1 className="text-white1 text-fw-strong">
                {metaInfo?.bannerTitle}
              </h1>
              <p className="description text-white1 text-fw-regular">
                {metaInfo?.bannerDescription?.bannerDescription}
              </p>
            </div>
          </div>
        </section>
        <section className="new-agency-filter">
          <div className="container-lg">
            <p className="filter-header text-fw-medium">Filter</p>

            <div className="new-agency-filter__grid">
              {filterData.map((el, index) => (
                <div
                  className={`dropdown ${el.open ? "open" : ""}`}
                  key={index}
                >
                  <div
                    className="dropdown-btn text-fw-regular text-clr-black1"
                    onClick={() => handleDropdown(index)}
                  >
                    {el.heading}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                    >
                      <path
                        d="M1 1L7 7L13 1"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>

                  <div className="dropdown-content">
                    <FilterDropdownItem
                      data={el}
                      title={el?.title}
                      slugData={location.pathname.split("/")[2]}
                      categoryData={otherCityAgencies}
                      handleFilterChange={e =>
                        handleFilterChange(e, el?.title, el?.filterData)
                      }
                      filters={filters}
                    />
                  </div>
                </div>
              ))}
              <div className="mobile-filter" onClick={e => handleModal()}>
                Filters
              </div>

              <div className="select-container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M3 16L7 20L11 16"
                    stroke="black"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7 20V4"
                    stroke="black"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21 8L17 4L13 8"
                    stroke="black"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17 4V20"
                    stroke="black"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <select
                  className="select-box"
                  value={sortBy}
                  onChange={handleSortChange}
                >
                  <option value="">Sort By</option>
                  <option value="title">Company Name</option>
                  <option value="ratings">Ratings</option>
                </select>
              </div>
            </div>
            <div className="new-agency-filter__values">
              {allFilterValues.map(e => (
                <>
                  <div
                    className="text-fw-regular"
                    onClick={() => RemoveFilterValues(e)}
                  >
                    {e}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M12 4L4 12"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M4 4L12 12"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </>
              ))}
              {allFilterValues.length > 0 && (
                <div className="clear" onClick={clearAllFilters}>
                  Clear all
                </div>
              )}
            </div>
          </div>
        </section>
        <section className="agency-cta">
          <div className="container-lg">
            <CallToAction
              title="Let our team find the right match for you"
              setShowModal={setShowModal}
            />
          </div>
        </section>
        <div className="new-agency-cards">
          <div className="container-lg">
            <p className="results text-fw-bold">
              Total Results: {filteredProducts?.length}
            </p>

            <div className="new-agency-cards__grid">
              {currentItems?.map((el, i) => (
                <NewAgencyCards
                  key={i}
                  image={el?.agencyLogo?.gatsbyImageData}
                  alt={el?.agencyLogo?.title}
                  title={el?.title}
                  ratings={el?.ratings}
                  city={el?.city}
                  employee={el?.employeeSize}
                  project={el?.projectSize}
                  price={el?.price}
                  reviewLink={el?.reviewLink}
                  description={el?.description.description}
                  sponsor={el?.sponsored}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  agencyLink={el?.agencyLink}
                  index={i}
                />
              ))}
            </div>
            <div className="pagination">
              <div>
                <button
                  className="previous-btn text-fw-regular"
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M12.8346 7.00008H1.16797M1.16797 7.00008L7.0013 12.8334M1.16797 7.00008L7.0013 1.16675"
                      stroke="black"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Previous
                </button>
              </div>
              <div className="pagination-number">
                <span>{currentPage}</span>/<span>{totalPages}</span>
              </div>
              <div>{renderPageButtons()}</div>
              <div>
                <button
                  className="next-btn text-fw-regular"
                  onClick={() => paginate(currentPage + 1)}
                  disabled={
                    currentPage ===
                    Math.ceil(filteredProducts?.length / itemsPerPage)
                  }
                >
                  Next
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M4.16797 10.0001H15.8346M15.8346 10.0001L10.0013 4.16675M15.8346 10.0001L10.0013 15.8334"
                      stroke="black"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        <section className="agency-faq">
          <div className="container">
            <Faq data={metaInfo?.faq} />
          </div>
        </section>

        <section className="other-agencies-category">
          <div className="container-lg">
            <h2 className="text-fw-bold">Other Services in the City</h2>
            <div className="other-agencies-category__grid">
              {otherServices.map((e, i) => (
                <Link
                  to={`/agencies/${e.slug}`}
                  className="other-agencies-category__grid-item"
                  key={i}
                >
                  {e.title}
                </Link>
              ))}
            </div>
          </div>
        </section>
        {otherCityAgencies.length > 0 && (
          <section className="other-agencies">
            <div className="container-lg">
              <h2 className="text-fw-bold">
                {pageContext?.categoryTitle} Agency in other Cities
              </h2>
              <div className="other-agencies__grid">
                {otherCityAgencies?.map((e, i) => (
                  <a
                    href={`/agencies/${e?.dSlug}/${e?.conSlug}/${e?.cSlug}`}
                    key={i}
                  >
                    <div className="other-agencies__grid-item">{e.title}</div>
                  </a>
                ))}
              </div>
            </div>
          </section>
        )}
        <section className="footer-agency-cta">
          <div className="container-lg">
            <CallToAction
              title="Let our team find the right match for you"
              setShowModal={setShowModal}
            />
          </div>
        </section>
        <MobileFilters location={location.href} />
        {showModal && (
          <ModalForm
            showModal={showModal}
            location={location.href}
            setShowModal={setShowModal}
          />
        )}
        <FilterPopup
          showFilter={showFilter}
          filterValues={allFilterValues}
          setShowFilter={setShowFilter}
          clearAllFilters={clearAllFilters}
          handleModal={handleModal}
          data={filterData}
          filters={filters}
          applyFilter={applyFilter}
          categoryData={otherCityAgencies}
        />
      </Layout>
    </>
  )
}

export default AgencyCountryTemplate

export const pageQuery = graphql`
  query($filter1: String!, $filter2: String!) {
    allContentfulAgenyExperts(
      filter: {
        agencyCategory: { elemMatch: { slug: { eq: $filter1 } } }
        cityLocation: {
          elemMatch: { country: { elemMatch: { slug: { eq: $filter2 } } } }
        }
      }
    ) {
      nodes {
        id
        cityLocation {
          title
          slug
          citySlug
          country {
            title
            slug
            categoriesWithMetaInfo {
              bannerTitle
              bannerDescription {
                bannerDescription
              }
              seoTitle
              seoDescription {
                seoDescription
              }
              category {
                title
                slug
              }
              faq {
                title
                description {
                  description
                }
              }
            }
          }
        }
        title
        price
        featured
        projectSize
        city
        agencyLink
        ratings
        reviews
        reviewLink
        employeeSize
        price
        agencyLogo {
          gatsbyImageData(
            quality: 100
            placeholder: BLURRED
            layout: CONSTRAINED
          )
          title
        }
        description {
          description
        }
      }
    }
    allContentfulAgencyCategory {
      nodes {
        title
        slug
        ageny_experts {
          cityLocation {
            title
            citySlug
            country {
              title
              slug
            }
          }
          agencyCategory {
            title
            slug
          }
        }
      }
    }
  }
`
